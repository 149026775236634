p {
  margin-bottom: 0;
}

.hover-pointer {
  &:hover {
    cursor: pointer;
  }
}

.shared-table {
  width: 100%;
  background-color: white;
  border: solid 1px #95a5a6;

  tr {
    border-top: solid 1px #95a5a6;
    border-bottom: solid 1px #95a5a6;
  }

  th,
  td {
    // text-align: center;
    padding: 8px;
    font-size: 0.875rem;
  }
}

.shared-table-sticky {
  display: block;
  max-height: 500px;
  overflow-y: scroll;

  th {
    position: sticky;
    top: 0;
    background-color: white;
    border: solid 1px white;
    border-bottom: solid 1px #95a5a6;
  }

  &::-webkit-scrollbar {
    height: 6px;
    width: 6px;
    /* width of the entire scrollbar */
  }

  &::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.1);
    /* color of the tracking area */
  }

  &::-webkit-scrollbar-thumb {
    background-color: #95a5a6;
    /* color of the scroll thumb */
    border-radius: 20px;
    /* roundness of the scroll thumb */
  }
}

.shared-filter_list {
  margin: 6px 0;
  padding: 6px;
  background-color: white;
  border-radius: 4px;

  .select-filter__control {
    width: 60px;
    min-height: 18px;
    font-size: 14px;
    margin-left: 8px;
    padding: 0;
  }

  .select-filter__option {
    font-size: 14px;
    padding: 8px;
    min-height: 18px;
  }

  .list-pagination {
    i {
      font-size: 20px;
      margin: 0 4px;
      color: gray;

      &:hover {
        cursor: pointer;
        color: #2980b9;
        font-weight: bold;
      }
    }

    i.btn-control-disabled {
      &:hover {
        cursor: unset;
        color: gray;
        font-weight: normal;
      }
    }

    .pagination-label {
      margin: 0 12px;
    }
  }

  .list-number-pagination {
    button {
      background: none;
      border: none;
      font-size: 20px;
    }

    a {
      font-size: 20px;
      margin: 0 4px;
      color: #000;

      &:hover {
        cursor: pointer;
        color: #2980b9;
        font-weight: bold;
      }
    }

    a.btn-control-disabled {
      &:hover {
        cursor: unset;
        color: #000;
        font-weight: normal;
      }
    }

    .pagination-label {
      margin: 0 12px;
      width: fix-content;

    }

    .active {
      color: #2980b9;
      font-weight: bold;
    }
  }
}

.shared-filter-top_list {
  margin: 9px 0;
  padding: 6px;
  border-radius: 4px;

  .select-filter__control {
    width: 60px;
    min-height: 18px;
    font-size: 16px;
    margin-left: 8px;
    padding: 0;
  }

  .select-filter__option {
    font-size: 16px;
    padding: 8px;
    min-height: 18px;
  }

  .list-pagination {
    button {
      background: none;
      border: none;
      font-size: 20px;
    }

    i {
      font-size: 15px;
      margin: 0 4px;
      color: gray;

      &:hover {
        cursor: pointer;
        color: #2980b9;
        font-weight: bold;
      }
    }

    i.btn-control-disabled {
      &:hover {
        cursor: unset;
        color: gray;
        font-weight: normal;
      }
    }

    .pagination-label {
      margin: 0 12px;
    }
  }

  .list-number-pagination {
    a {
      font-size: 18px;
      margin: 0 4px;
      color: #000;

      &:hover {
        cursor: pointer;
        color: #2980b9;
        font-weight: bold;
      }
    }

    a.btn-control-disabled {
      &:hover {
        cursor: unset;
        color: #000;
        font-weight: normal;
      }
    }

    .pagination-label {
      margin: 0 12px;
      width: fix-content;
    }

    .active {
      color: #2980b9;
      font-weight: bold;
    }
  }
}

.shared-input-wrapper {
  .job-select__control {
    margin: 8px 0;
  }
}

.shared-input {
  margin: 8px 0;

  input {
    width: 100%;
    padding: 8px;
    border: solid 1px #95a5a6;
    border-radius: 4px;

    &:focus {
      border: 2px solid #2980b9;
      outline: none;
      padding: 7px;
    }
  }

  textarea {
    width: 100%;
    padding: 8px;
    border: solid 1px #95a5a6;
    border-radius: 4px;

    &:focus {
      border: 2px solid #2980b9;
      outline: none;
      padding: 7px;
    }
  }
}

.shared-select__control {
  margin: 8px 0;
  border: solid 1px #95a5a6;

  .shared-select__single-value {
    color: black;
  }
}

.react-datepicker-wrapper {
  width: 100%;

  input {
    width: 100%;
    padding: 8px;
    border: solid 1px #95a5a6;
    border-radius: 4px;
  }
}

.Toastify__toast--success {
  background: #2ecc71;
}


.shimmerBG {
  margin: 8px 0;
  animation-duration: 2.2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: shimmer;
  animation-timing-function: linear;
  background: #ffffff;   background: linear-gradient(to right, #F6F6F6 8%, #F0F0F0 18%, #F6F6F6 33%);
  background-size: 1200px 100%;
}


@-webkit-keyframes shimmer {
  0% {
      background-position: -100% 0;
  }

  100% {
      background-position: 100% 0;
  }
}

@keyframes shimmer {
  0% {
      background-position: -1200px 0;
  }

  100% {
      background-position: 1200px 0;
  }
}

.animation-fadein {
  opacity: 0; /* Mulai dengan opacity 0 */
  animation: fadeIn 1.5s ease-in-out forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0; /* Mulai dengan opacity 0 */
  }
  to {
    opacity: 1; /* Selesaikan dengan opacity 1 */
  }
}

// Product search

// Card 
.product-search-card {
  border-radius: 8px;
  background: white;
  padding: 12px 4px;
  margin: 12px 0;
  max-width: 370px;
  min-height: 650px;
  display: flex;
  overflow: hidden;
  justify-content: space-between;
  flex-direction: column;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.05);
  
  .col-md-12, .col {
    padding-left: 6px !important;
    padding-right: 6px !important;
  }

  .product-search-card__title {
    font-weight: bold;
    font-size: 16px;
    width: 100%;
    margin-bottom: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    line-clamp: 1;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  .product-search-card__manufacturer {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  .product-search-card__long-description {
    width: 100%;
    overflow-wrap: break-word;
    text-align: justify;
    word-break: break-all;
    &.ellipsis-hide {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      line-clamp: 4;
      -webkit-box-orient: vertical;
    }
  }
  
  .product-search-card__description {
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.catalog-card {
  height: 760px;
}

.inventory-card {
  height: 680px !important;
}