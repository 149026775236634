////////// COLOR SYSTEM //////////

$blue: #5e50f9;
$indigo: #6610f2;
$purple: #6a008a;
$pink: #e91e63;
$red: #f96868;
$orange: #f2a654;
$yellow: #f6e84e;
$green: #46c35f;
$teal: #58d8a3;
$cyan: #57c7d4;
$black: #000;
$white: #ffffff;
$white-smoke: #f2f7f8;
$violet: #41478a;
$darkslategray: #2e383e;
$dodger-blue: #3498db;

$colors: (
  blue: $blue,
  indigo: $indigo,
  purple: $purple,
  pink: $pink,
  red: $red,
  orange: $orange,
  yellow: $yellow,
  green: $green,
  teal: $teal,
  cyan: $cyan,
  white: $white,
  gray: #434a54,
  gray-light: #aab2bd,
  gray-lighter: #e8eff4,
  gray-lightest: #e6e9ed,
  gray-dark: #0f1531,
  black: #000000,
);

$theme-colors: (
  primary: #248afd,
  secondary: #a3a4a5,
  success: #71c016,
  info: #68afff,
  warning: #f5a623,
  danger: #ff4747,
  light: #f8f9fa,
  dark: #1a1f26,
);

$theme-gradient-colors: (
  primary: linear-gradient(to right, #da8cff, #9a55ff),
  secondary: linear-gradient(to right, #e7ebf0, #868e96),
  success: linear-gradient(to right, #84d9d2, #07cdae),
  info: linear-gradient(to right, #90caf9, #047edf 99%),
  warning: linear-gradient(to right, #f6e384, #ffd500),
  danger: linear-gradient(to right, #ffbf96, #fe7096),
  light: linear-gradient(to bottom, #f4f4f4, #e4e4e9),
  dark: linear-gradient(89deg, #5e7188, #3e4b5b),
);

////////// COLOR SYSTEM //////////

////////// COLOR VARIABLES //////////
$content-bg: #ededed;
$border-color: #c9ccd7;
////////// COLOR VARIABLES //////////

////////// SOCIAL COLORS //////////
$social-colors: (
  twitter: #2caae1,
  facebook: #3b579d,
  google: #dc4a38,
  linkedin: #0177b5,
  pinterest: #cc2127,
  youtube: #e52d27,
  github: #333333,
  behance: #1769ff,
  dribbble: #ea4c89,
  reddit: #ff4500,
);
////////// SOCIAL COLORS //////////

////////// FONTS VARIABLES /////////

$type1: "Roboto", sans-serif;
$font-weight-light: 300;
$font-weight-medium: 500;
$font-weight-bold: 700;
$default-font-size: 0.875rem; // 14px as base font size
$text-muted: #686868;
$body-color: #000;

////////// FONTS VARIABLES /////////

////////// SIDEBAR ////////

$sidebar-width-lg: 237px;
$sidebar-width-mini: 185px;
$sidebar-width-icon: 70px;

$sidebar-light-bg: #282f3a;
$sidebar-light-menu-color: #d0cfcf;
$sidebar-light-submenu-color: #d0cfcf;
$sidebar-light-menu-active-bg: #e7e7e7;
$sidebar-light-menu-active-color: #282f3a;
$sidebar-light-menu-hover-bg: #e7e7e7;
$sidebar-light-menu-hover-color: #667895;
$sidebar-light-submenu-active-color: #9a94a7;
$sidebar-light-submenu-hover-color: #667895;
$sidebar-light-menu-icon-color: $sidebar-light-menu-color;
$sidebar-light-menu-arrow-color: #d0cfcf;

$sidebar-menu-font-size: 0.875rem;
$sidebar-menu-padding-left: 1rem;
$sidebar-menu-padding-right: 1.937rem;
$sidebar-menu-padding-top: 0.75rem;
$sidebar-menu-padding-bottom: 0.75rem;

$sidebar-submenu-padding: 0.25rem 0 0.75rem 3.07rem;
$sidebar-submenu-font-size: 0.875rem;
$sidebar-submenu-item-padding: 0.7rem 1rem;

$sidebar-icon-font-size: 1rem;
$sidebar-arrow-font-size: 0.625rem;

$sidebar-profile-bg: transparent;
$sidebar-profile-padding: 0.75rem 1.625rem 0.75rem 1.188rem;

$sidebar-mini-menu-padding: 0.8125rem 1rem 0.8125rem 1rem;

$sidebar-icon-only-menu-padding: 0.5rem 1.625rem 0.5rem 1.188rem;
$sidebar-icon-only-submenu-padding: 0 0 0 1.5rem;

$icon-only-collapse-width: 190px;
$icon-only-menu-bg-light: #282f3a;

///////// SIDEBAR ////////

///////// NAVBAR ////////

$navbar-height: 70px;
$navbar-bg: $white;
$navbar-menu-color: #9b9b9b;
$navbar-font-size: 1rem;
$navbar-icon-font-size: 0.9375rem;

///////// NAVBAR ////////

///////// FOOTER ////////
$footer-bg: $content-bg;
$footer-color: color(dark);
///////// FOOTER ////////

///////// CARD ////////
$card-description-color: #76838f;
$card-title-color: #787878;
///////// CARD ////////

///////// BUTTONS ////////

$button-fixed-width: 150px;
$btn-padding-y: 0.875rem;
$btn-padding-x: 1.5rem;
$btn-line-height: 1;

$btn-padding-y-xs: 0.5rem;
$btn-padding-x-xs: 0.75rem;

$btn-padding-y-sm: 0.5rem;
$btn-padding-x-sm: 0.81rem;

$btn-padding-y-lg: 1rem;
$btn-padding-x-lg: 3rem;

$btn-font-size: 0.875rem;
$btn-font-size-xs: 0.625rem;
$btn-font-size-sm: 0.875rem;
$btn-font-size-lg: 0.875rem;

$btn-border-radius: 0.1875rem;
$btn-border-radius-xs: 0.1875rem;
$btn-border-radius-sm: 0.1875rem;
$btn-border-radius-lg: 0.1875rem;

///////// BUTTONS ////////

////////// TOOLTIP VARIABLES //////////

//default styles
$tooltip-font-size: 0.75rem;
$tooltip-padding-y: 0.4rem;
$tooltip-padding-x: 0.75rem;
$tooltip-border-radius: 0.375rem;

////////// TOOLTIP VARIABLES //////////

/////////  FORMS /////////

$input-bg: color(white);
$input-border-radius: 2px;
$input-placeholder-color: #c9c8c8;
$input-font-size: 0.875rem;

$input-padding-y: 0.875rem;
$input-padding-x: 1.375rem;
$input-line-height: 1;

$input-padding-y-xs: 0.5rem;
$input-padding-x-xs: 0.75rem;

$input-padding-y-sm: 0.5rem;
$input-padding-x-sm: 0.81rem;

$input-padding-y-lg: 0.94rem;
$input-padding-x-lg: 1.94rem;

$input-height: 2.875rem;
$input-height-sm: 2.575rem;
$input-height-lg: 3.175rem;

///////// FORMS /////////

////////  DROPDOWNS ///////

$dropdown-border-color: $border-color;
$dropdown-divider-bg: $border-color;
$dropdown-link-color: $body-color;
$dropdown-header-color: $body-color;

////////  DROPDOWNS ///////

//////// TABLES ////////

$table-accent-bg: $content-bg;
$table-hover-bg: #eaeaf1;
$table-cell-padding: 1.25rem 0.9375rem;
$table-border-color: $border-color;
$table-inverse-bg: #2a2b32;
$table-inverse-color: color(white);

//////// TABLES ////////

////////// MEASUREMENT AND PROPERTY VARIABLES //////////

$border-property: 1px solid $border-color;
$card-spacing-y: 1.875rem;
$card-padding-y: 1.25rem;
$card-padding-x: 1.437rem;
$card-border-radius: 0;
$grid-gutter-width: 30px;
$action-transition-duration: 0.25s;
$action-transition-timing-function: ease;
////////// OTHER VARIABLES //////////

////////// BREAD CRUMBS VARIABLES //////////

// default styles
$breadcrumb-padding-y: 0.56rem;
$breadcrumb-padding-x: 1.13rem;
$breadcrumb-item-padding: 0.5rem;
$breadcrumb-margin-bottom: 1rem;
$breadcrumb-font-size: $default-font-size;
$breadcrumb-bg: transparent;
$breadcrumb-border-color: $border-color;
$breadcrumb-divider-color: $gray-600;
$breadcrumb-active-color: $gray-700;
$breadcrumb-divider: "/";

// custom styles
$breadcrumb-custom-padding-y: 0;
$breadcrumb-custom-padding-x: 0;
$breadcrumb-custom-item-padding-y: 0.56rem;
$breadcrumb-custom-item-padding-x: 10px;
$breadcrumb-custom-item-color: $black;
$breadcrumb-item-bg: #dbe3e6;

////////// BREAD CRUMBS VARIABLES //////////

////////// MODALS VARIABLES //////////

$modal-inner-padding: 0.9375rem;
$modal-dialog-margin: 10px;
$modal-dialog-margin-y-sm-up: 30px;
$modal-title-line-height: $line-height-base;
$modal-content-bg: $white;
$modal-content-box-shadow-xs: 0 3px 9px rgba($black, 0.5);
$modal-content-box-shadow-sm-up: 0 5px 15px rgba($black, 0.5);

$modal-backdrop-bg: $black;
$modal-backdrop-opacity: 0.5;

$modal-header-border-color: $border-color;
$modal-content-border-color: $border-color;
$modal-footer-border-color: $border-color;

$modal-header-border-width: $border-width;
$modal-content-border-width: $border-width;
$modal-footer-border-width: $border-width;

$modal-header-padding-x: 26px;
$modal-header-padding-y: 25px;

$modal-body-padding-x: 26px;
$modal-body-padding-y: 35px;

$modal-footer-padding-x: 31px;
$modal-footer-padding-y: 15px;

$modal-lg: 90%;
$modal-md: 500px;
$modal-sm: 300px;
$modal-transition: transform 0.4s ease;

////////// MODALS VARIABLES //////////

/////////  TABS VARIABLES //////////
$nav-tabs-border-color: #ebedf2;
$nav-tabs-link-bg: #f6f8fa;
$nav-tabs-link-color: #000000;
$nav-tabs-link-hover-border-color: $nav-tabs-border-color $nav-tabs-border-color
  $nav-tabs-border-color;
$nav-tabs-link-active-color: $body-color;
$nav-tabs-link-active-bg: $white;
$nav-tabs-link-active-border-color: $nav-tabs-border-color
  $nav-tabs-border-color $nav-tabs-link-active-bg;
$nav-pills-custom-bg: #fcfcfd;
$text-gray: gray;
$circle-img-border: $white;
////////   TABS VARIABLES /////////

////////   CALENDAR  //////////////
$fullcalendar-color: #a8b2b9;
////////   CALENDAR  //////////////

///////// TICKETS-TAB ///////
$tab-head-bg: darken(#f2edf3, 2%);
$accent-color: $white;
///////// TICKETS-TAB ///////

///////// CHATS ///////
$chat-container-height: 100%;
$chat-bottom-btn: $white;
$form-focus-bg: $white;
///////// CHATS ///////

///////// KANBAN ///////
$private-btn-bg: #eae2ec;
///////// KANBAN ///////

///////// SETTINGS PANEL ////////
$settings-panel-width: 300px;
$settings-panel-bg: $white;
///////// SETTINGS PANEL ////////
